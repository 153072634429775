<h5>Mongo DB
    <img src="../../../assets/media/mongo-logo.png" title="Mongo"/>
</h5>
<p>
    I am using a Mongo DB here to store the blog entries, I wanted to show that I have good experience using databases in an app.  I am pretty new to mongo and most of my experiance has been in SQL server and Oracle but I do like Mongo and would love more exposure to it.
</p>
<br/>
<h5>API Calls
    <img src="../../../assets/media/aspnetcore-logo.png" title=".NetCore"/>
</h5>
<p>
    So my backend makes calls to my database and then sends them to the frontend.  I think there are ways of hitting the DB with just the UI but wanted to show how to create a API with database calls.  You can see all the calls on the swagger page and that is how I make entries with the post on swagger.
</p>
