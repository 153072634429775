<div id="tooloverlay" [@slideInOut] [ngClass]="{'tooloverlaysm' : toolActive === false, 'tooloverlaylg' : toolActive === true}">
    <div id="toolbuttons">
        <button class="btn btn-primary btn-sm" title="Google Map" (click)="this.toolAction('google')"><i class="fa fa-info fa-2x" aria-hidden="true"></i></button>
        <button class="btn btn-primary btn-sm" title="Close Tool Tip" (click)="this.toolClose()"><i class="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
    </div>
    <div id="googlemapoverlay" *ngIf="this.googleMapOverlay" [@slideInOut]>
        <div id="googlemap-area">
            <googlemap-overlay></googlemap-overlay>
        </div>
    </div>
</div>
<div id="googlewrapper">
    <div id="oldtimeradio" class="form-group">
        <label class="radio-inline">
            <input type="radio" name="optradio" [value]="1" [(ngModel)]="this.radioData" (click)="setStyle('1')">Silver</label>
        <label class="radio-inline">
            <input type="radio" name="optradio" [value]="2" [(ngModel)]="this.radioData" (click)="setStyle('2')">Night</label>
        <label class="radio-inline">
            <input type="radio" name="optradio" [value]="3" [(ngModel)]="this.radioData" (click)="setStyle('3')">Normal</label>
    </div>
    <div id="searcharea">
        <div id="searchbutton">
            <a class="btn btn-primary btn-l" (click)="findSearch()">Submit</a>
        </div>
        <div id="searchfield">
            <input class="inputttt" placeholder="Address Look Up..." id="searchfield" [(ngModel)]="this.searchValue" (keydown.enter)="findSearch()"/>
        </div>
    </div>
    <ngui-map center="Omaha, Nebraska" 
            style="width:100%;height:700px;margin-bottom:15px;"
            zoom = 4
            gestureHandling = "greedy"
            (mapReady$)="onMapReady($event)"
            >
    </ngui-map>
    <div id="mapmenu">
        <ul>
            <li><a class="btn btn-primary btn-l" id="marker_button" (click)="setMarkers()" title="Some Markers"><i class="fa fa-map-marker fa-inverse"></i></a></li>
            <li><a class="btn btn-primary btn-l" id="find_me_button" (click)="findMe()" title="Find Me"><i class="fa fa-user fa-inverse"></i></a></li>
            <li><a class="btn btn-primary btn-l" id="traffic_button" (click)="setTrafficLayer()" title="Traffic"><i class="fa fa-road fa-inverse"></i></a></li>
            <li><a class="btn btn-primary btn-l" id="weather_button" (click)="setNOAAKML()" title="Weather Events"><i class="fa fa-bolt fa-inverse"></i></a></li>
            <li><a class="btn btn-primary btn-l" id="air_button" (click)="setAirQualityKML()" title="Air Quality"><i class="fa fa-cloud fa-inverse"></i></a></li>
            <li><a class="btn btn-primary btn-l" id="fire_button" (click)="setFireKML()" title="Fires"><i class="fa fa-fire fa-inverse"></i></a></li>
            <li><a class="btn btn-primary btn-l" id="drawing_button" (click)="enableDrawing()" title="Drawing"><i class="fa fa-pencil fa-inverse"></i></a></li>
            <li><a class="btn btn-primary btn-l" id="directions_button" (click)="enableDirections()" title="Directions"><i class="fa fa-map-signs fa-inverse"></i></a></li>
        </ul>
    </div>
    <div id="directions" *ngIf="is_directions">
        <input class="inputttt" placeholder="From...." id="addressfrom" [(ngModel)]="this.addressFrom"/>
        <input class="inputttt" placeholder="To...." id="addressto" [(ngModel)]="this.addressTo"/>
        <a id= "directionsbutton" class="btn btn-primary btn-l" (click)="getDirections()">Submit</a>
    </div>
    <div id="info_details" [innerHTML]="info_details"></div>
</div>