<img class="round-avatar-inner" src="https://avatars.githubusercontent.com/u/36893512?s=400&v=4"/>
<div id="aboutwrapper">
    <h4>About Me</h4>
    <p> 
        My name is Jeff Heldridge, I am currently a Software Engineer and have been coding for over 7 years. I have pretty much been in the Financial Service Industry for most of that time and would be excited to branch out from it a bit.  Although I do really enjoy coding outside of work to learn new things, it’s also a hobby for me.
    </p>
    <p>
        I wanted to make a site for myself to keep me busy as well as learn some new stuff like Azure hosting and Mongo.  For most of my seven years I have been a full stack developer primarily working in Angular/Angular.js and .Net.  My current job though has me coding in Java in a Spring framework using Maven, and Eclipse with a Dojo/JavaScript frontend.
    </p>
    <p>
        I got started in coding by originally getting tired of my data entry job and learned how to program Excel Macros in .VBA and was able to automate a lot of my job.  I realized I needed to learn how to create web applications to automate even more things so I ended up going to school for an associate’s degree in web design from Metro Community College, and then eventually a bachelor’s degree from Bellevue University in Computer Science.
    </p>
    <p>
        Beyond coding I have been able to develop some good soft skills as well. I have been able to work for some really great people who have taught me to be a good influence on others, as well as good communicator, leader, and someone who is able to work well with other groups.
    </p>  
    <div id="resumewrapper">
        <div class="row">
          <div id="columnone" class="col-sm">
            <h3>Work Experiance</h3>
            <div class="card">
              <div class="card-body">
                <h6><span class="workdate">06/2021-Present</span></h6>
                <h5><span class="worktitle">Manager - Software Developement & Engineering</span></h5>
                <h6><span class="companytitle">Charles Schwab</span></h6>
                <ul>
                  <li><span class="jobinfo"></span>In a dev 4 role supporting the Securities Lending group with a native app written in Java on a spring framework to lend securities through DTCC/Loanet.</li>
                  <li>Skills used/learned: STS, Java, MQ messaging, UNIX, Oracle DB, Linux, Jenkins, JavaScript, Dojo, Bitbucket</li>
                </ul>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h6><span class="workdate">02/2020-06/2021</span></h6>
                <h5><span class="worktitle">Software Engineer</span></h5>
                <h6><span class="companytitle">TD Ameritrade</span></h6>
                <ul>
                  <li><span class="jobinfo"></span>Supported the Securities Lending group with a native app written in Java on a spring framework to lend securities through DTCC/Loanet.</li>
                  <li>Skills used/learned: STS, Java, MQ messaging, UNIX, Oracle DB, Linux, Jenkins, JavaScript, Dojo, Bitbucket</li>
                </ul>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h6><span class="workdate">09/2017 – 02/2020</span></h6>
                <h5><span class="worktitle">Senior Developer</span></h5>
                <h6><span class="companytitle">TD Ameritrade</span></h6>
                <ul>
                  <li><span class="jobinfo"></span>Supported and built applications for the Retail group to make client interaction easier as well as Risk and Supervision more transparent.</li>
                  <li>Skills used/learned: ASP.Net, .Net, .Netcore, Internet Information Services(IIS), SQL Server Integration Services(SSIS), Angular, Javascript, SQL Server, SQL Server Managment Studio, Visual Studio, VS Code, Bitbucket</li>
                </ul>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h6><span class="workdate">04/2016 – 9/2017</span></h6>
                <h5><span class="worktitle">Developer II</span></h5>
                <h6><span class="companytitle">TD Ameritrade</span></h6>
                <ul>
                  <li><span class="jobinfo"></span>Supported and built applications for the Risk group to provide automation of risk assesments, classifications, and rankings.  In addition to automation of end user tasks.</li>
                  <li>Skills used/learned: .Net, Angular, Javascript, SQL Server, SQL Server Managment Studio, Visual Studio, VS Code</li>
                </ul>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h6><span class="workdate">03/2015 – 4/2016</span></h6>
                <h5><span class="worktitle">Developer II</span></h5>
                <h6><span class="companytitle">TD Ameritrade</span></h6>
                <ul>
                  <li><span class="jobinfo"></span>Supported and built applications to automate back office user's tasks using multiple data sets to increase productivity and reduce risk from FINRA and SEC rules.</li>
                  <li>Skills used/learned: .Net, Angular.JS, Javascript, SQL Server, SQL Server Managment Studio, Visual Studio, VBA - Excel Macros</li>
                </ul>
            </div>
          </div>
            <div class="card">
              <div class="card-body">
                <h6><span class="workdate">08/2013 – 3/2015</span></h6>
                <h5><span class="worktitle">Specialist</span></h5>
                <h6><span class="companytitle">TD Ameritrade</span></h6>
                <ul>
                  <li><span class="jobinfo"></span>Produced KRI's, KPI's to provide insite into stock record processes to determine risk to the firm.  Also produced reports for FINRA and SEC to show compliance with industry standards.</li>
                  <li>Skills used/learned: Javascript, SQL Server, SQL Server Managment Studio, Visual Studio, VBA - Excel Macros, Tableau</li>
                </ul>
              </div>  
          </div>
        </div>
      <div id="columntwo" class="col-sm">
        <h3>Skills and Competences</h3>
        <div id="skills"></div> 
        <h3>Education</h3>
        <div class="card">
          <div class="card-body">
            <h6><span class="workdate">10/2016 – 8/2018</span></h6>
            <h5><span class="worktitle">Bellevue University</span></h5>
            <h6>Bachelor of Science in Computer Information Systems</h6>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h6><span class="workdate">8/2014 – 8/2016</span></h6>
            <h5><span class="worktitle">Metropolitan Community College</span></h5>
            <h6>Associates of IT in Web Design</h6>
          </div>
        </div>
        <h3>Achievements and Certifications</h3>
        <div class="card">
          <div class="card-body">
            <h6>Series 7</h6>
            <ul>
              <li>Passed Series 7 exam to become a registered representative by FINRA</li>
            </ul>
            <h6>Series 63</h6>
            <ul>
              <li>Passed NASAA exam for state registration</li>
            </ul>
            <h6>Dev Up 2018</h6>
            <ul>
              <li>Participated in DevUp conference in St. Louis with focuses on C#, Angular, Blazor, and .Net</li>
            </ul>
            <h6>Azure Days 2018</h6>
            <ul>
              <li>Participated in Azure Days Kansas City with topics covering at range of Azure functions</li>
            </ul>
          </div>  
        </div>
        <h3>Interests</h3>
        <div class="card">
          <div class="card-body">
            Physics | Science | Astronomy | Gaming | Programming | Astrophotography | Music | College Football | Animals | Family | Friends
          </div>
        </div>
      </div>
  </div>
</div>