<h5>Polygon.IO
    <img src="../../../assets/media/poly-logo.png" title="Polygon.IO"/>
</h5>
<p>
    I am using the Polygon.IO API to pull back some financial data on stocks by symbol.  Its a really cool API and easy to use, 
    they dont offer the current quote/price for free and I didnt want to pay $199.00 a month for it so sorry this is just for 
    demo purposes. Below are a few example symbols to help if you dont have any handy:
</p>
<ul>
    <li>F : Ford</li>
    <li>TSLA : Tesla</li>
    <li>MSFT : Microsoft</li>
    <li>AAPL : Apple</li>
    <li>GOOG : Google</li>
    <li>FB : Facebook</li>
</ul>
<br/>
<h5>API Calls
    <img src="../../../assets/media/aspnetcore-logo.png" title=".NetCore"/>
</h5>
<p>
    So my backend makes the calls, I could do it from the front end but then my key would be exposed so just put it all in the backend 
    so I could hide my credentials a little better.
</p>
<p>
    Its a pretty neat API for financial data if you are interested:
    <br/><a href="https://polygon.io/" target="_blank">Polygon.IO</a> 
</p>