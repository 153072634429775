<h5>Angular Games
    <img src="../../../assets/media/angular-logo.png" title="Angular"/>
    <img src="../../../assets/media/javascript-logo.png" title="JavaScript"/>
    <img src="../../../assets/media/typescript_logo_new.png" title="TypeScript"/>
</h5>
<p>
    I didnt build any of these but did want to provide a little fun on the app, so you can pick from a few different games that are built into this app and are all in Angular.
</p>
<p>
    The only coding take-away here is that I can use other peoples code natively in app and plus got to see a few tricks in how they built these.
</p>
<p>
    I ll make one caveat that I did add buttons to both of these games so they work on mobile.
</p>
<br/>
<h5>ngx-Snake</h5>
<p>
    This is a cool game I found all in Angular (Desktop preferred). Please check out the info below if curious where I got it from.
</p>
<table>
    <tr>
        <td>
            Developer:
        </td>
        <td>
            <a href="https://github.com/SamirHodzic" target="_blank">Samir Hodzic</a> 
        </td>
    </tr>
    <tr>
        <td>
            Repo:
        </td>
        <td>
            <a href="https://github.com/SamirHodzic/ngx-snake" target="_blank">GitHub</a> 
        </td>
    </tr>
</table>
<br/>
<h5>ng-tetris</h5>
<p>
    Another neat game I found all in Angular (Desktop preferred). Please check out the info below if curious where I got it from.
</p>
<table>
    <tr>
        <td>
            Developer:
        </td>
        <td>
            <a href="https://github.com/melcor76" target="_blank">Michael Karén</a> 
        </td>
    </tr>
    <tr>
        <td>
            Repo:
        </td>
        <td>
            <a href="https://github.com/melcor76/ng-tetris" target="_blank">GitHub</a> 
        </td>
    </tr>
</table>