<div id="footerwrapper">
    <div class = "form-group row">
        <div class="col-sm-3">
            <h5>Links</h5>
            <ul>
                <li><a href='https://twitter.com/JMHeldridge' target='_blank'>Twitter</a></li>
                <li><a href='https://github.com/bull5150' target='_blank'>GitHub</a></li>
                <li><a href='https://www.linkedin.com/in/jeff-heldridge-02235698/' target='_blank'>LinkedIn</a></li>
                <li><a href='api/file/download'>My Resume(PDF)</a></li>
            </ul>    
        </div>
        <div class="col-sm-3">
            <h5>Pages in this APP:</h5>
            <ul>
                <li><a routerLink="/home">Home</a></li>
                <li><a routerLink="/about">About</a></li>
                <li><a routerLink="/blogread">Blog</a></li>
                <li><a routerLink="/examples">Code Examples</a></li>
                <li><a routerLink="/contact">Contact Me</a></li>
                <li>Swagger Page</li>
            </ul>
        </div>
    </div>
</div>