<div id="formwrapper">
    <p>Please use the form below to send me an email about the site.</p>
    <form [formGroup] = "emailForm" class="form-horizontal">
        <label for="fname">First Name:</label>
        <input class="form-control" formControlName="fname" id="fname" placeholder="Enter Your First Name..."/>
        <label for="lname">Last Name:</label>
        <input class="form-control" formControlName="lname" id="lname" placeholder="Enter Your Last Name..."/>
        <label for="email">Email Address:</label>
        <input class="form-control" type="email" formControlName="email" id="email" placeholder="Enter Your Email Address..."/>
        <div id="emailalert" class="alert alert-dismissible alert-danger" *ngIf="this.alert">
            <button type="button" class="close" data-dismiss="alert" (click)="this.dismissAlert()">&times;</button>
            <strong>Oh snap! </strong>Please enter a valid email address.
        </div>
        <small>I will not save or distribute your email.</small><br/>
        <label for="company">Company:</label>
        <input class="form-control" formControlName="company" placeholder="Company Affiliation If Any..."/>
        <label for="reason">Reason For Email:</label>
        <select class="custom-select" formControlName="reason">
            <option value="">Select an Option</option>
            <option value="friendly">Friendly</option>
            <option value="feedback">Site Feedback</option>
            <option value="job">Job Inquery</option>
        </select>
        <label for="reciept">Confirmation Email?</label>
        <fieldset class="form-group" >
            <div class="form-check">
                <label class="form-check-label">
                <input type="radio" class="form-check-input" formControlName="reciept" id="recieptno" value="no">
                No
                </label>
            </div>
            <div class="form-check">
            <label class="form-check-label">
                <input type="radio" class="form-check-input" formControlName="reciept" id="recieptyes" value="yes">
                Yes
                </label>
            </div>
        </fieldset>
        <label for="message">Message</label>
        <textarea rows=10 class="form-control" formControlName="message" placeholder="Place Your Email Message Here..."></textarea>
    </form>
    <div id="submitbutton">
        <a class="btn btn-primary btn-l" (click)="submit()">Submit</a>
    </div>
</div>
