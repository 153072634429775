<div id="blogwrapper">
    <div class="text-center" id="loadingmask" *ngIf="infoLoaded == false">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <br/>Loading Please Wait...
    </div>
    <div *ngIf="infoLoaded == true">
        <h4>Developer Blog</h4>
        <div id="blog_area" *ngFor="let blog of blogList">
            <img src="{{blog.thumbImageURL}}" onError="src='../../../assets/media/profile_small.jpg'"> <h5>{{blog.createDate}}</h5>
            {{blog.blogEntry}}
            <br>
        </div>
    </div>
</div>