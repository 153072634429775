<h5>Angular Forms
    <img src="../../../assets/media/angular-logo.png" title="Angular"/>
</h5>
<p>
    Just some generic HTML forms tied to ngModel with Angular.  They are styled with bootstrap and if you enter some values you will be able to see them populated in the table below via ngModel. 
</p>
<br/>
<h5>Charts
    <img src="../../../assets/media/angular-logo.png" title="Angular"/>
    <img src="../../../assets/media/typescript_logo_new.png" title="Typescript"/>
    <img src="../../../assets/media/amcharts_logo.png" title="amCharts"/>
</h5>
<p>
    The charts are built using amCharts4 for Angular or Typescript even though most the code is straight Javascript.  amCharts 4 is fairly easy to use, in the social networks chart I flipped the data to an api call and added two years, so you can tell after you figure out the structure of the data for the chart its pretty easy to make your own.  
</p>
<p>
    <a href="https://www.amcharts.com/" target="_blank">AmCharts4</a> 
<br/>
</p>
<h5>API Calls
    <img src="../../../assets/media/aspnetcore-logo.png" title=".NetCore"/>
</h5>
<p>
    The data for the Socail Networks chart was flipped to C# because its a lot to just store a object that big on the front end.
</p>
