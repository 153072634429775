  <div id="infowrapper">
      <h4>
          Code Examples
      </h4>
      <p>
          Below are a few things I have built to hightlight some of my dev knowledge.  I added some icons at the bottom of each card so that you can see the
          skills used so you can quickly get to what that page pertains to as well as a info overlay (accessable on top right of the page) on each page that 
          really talks about the concepts 
          I am using on the page.
      </p>
  </div>
  <div class="card-deck">
    <div class="card bg-light">
        <img class="card-img-top" src="../../../assets/media/google_example.jpg" alt="Card image">
        <div class="card-body text-center">
            <h4 class="card-title titlehighlight">Google Maps</h4>
            <p class="card-text">Shows a lot of examples of using different options in Google Maps API</p>
            <a href="#google" class="btn btn-primary">See Example</a>
            <div class="devimage">
                <img src="../../../assets/media/typescript_logo_new.png" title="TypeScript"/>
                <img src="../../../assets/media/javascript-logo.png" title="JavaScript"/>
                <img src="../../../assets/media/googlemap-logo.png" title="Google Maps"/>
            </div>
        </div>
    </div>
    <div class="card bg-primary">
        <img class="card-img-top" src="../../../assets/media/twitter_example.jpg" alt="Card image">
        <div class="card-body text-center">
            <h4 class="card-title titlehighlight">Twitter API</h4>
            <p class="card-text">A page to pull my Tweets as well as let you search for others</p>
            <a href="#twitter" class="btn btn-primary">See Example</a>
            <div class="devimage">
                <img src="../../../assets/media/twitter-logo.png" title="Twitter"/>
                <img src="../../../assets/media/aspnetcore-logo.png" title=".NetCore"/>
            </div>
        </div>
    </div>
    <div class="card bg-light">
        <img class="card-img-top" src="../../../assets/media/blog_example.jpg" alt="Card image">
        <div class="card-body text-center">
            <h4 class="card-title titlehighlight">My Dev Blog</h4>
            <p class="card-text">Pulls blog entries from MongoDB database from .NetCore backend</p>
            <a href="#blog" class="btn btn-primary">See Example</a>
            <div class="devimage">
                <img src="../../../assets/media/mongo-logo.png" title="Mongo"/>
                <img src="../../../assets/media/aspnetcore-logo.png" title=".NetCore"/>
            </div>
        </div>
    </div>
</div>
<div class="card-deck">
    <div class="card bg-primary">
        <img class="card-img-top" src="../../../assets/media/poly_example.png" alt="Card image">
        <div class="card-body text-center">
            <h4 class="card-title titlehighlight">Polygon.IO API</h4>
            <p class="card-text">Provides some real time market data from Polygon.IO on symbols entered.</p>
            <a href="#poly" class="btn btn-primary">See Example</a>
            <div class="devimage">
                <img src="../../../assets/media/poly-logo.png" title="Polygon.IO"/>
                <img src="../../../assets/media/aspnetcore-logo.png" title=".NetCore"/>
            </div>
        </div>
    </div>
    <div class="card bg-light">
        <img class="card-img-top" src="../../../assets/media/forms_example.jpg" alt="Card image">
        <div class="card-body text-center">
            <h4 class="card-title titlehighlight">Angular Forms</h4>
            <p class="card-text">Just a page with some simple charts, tables and form items.</p>
            <a href="#angularforms" class="btn btn-primary">See Example</a>
            <div class="devimage">
                <img src="../../../assets/media/typescript_logo_new.png" title="TypeScript"/>
                <img src="../../../assets/media/angular-logo.png" title="Angular"/>
                <img src="../../../assets/media/amcharts_logo.png" title="amCharts"/>
            </div>
        </div>
    </div>
    <div class="card bg-primary">
        <img class="card-img-top" src="../../../assets/media/games_example.png" alt="Card image">
        <div class="card-body text-center">
            <h4 class="card-title titlehighlight">Games</h4>
            <p class="card-text">Some games I found written in Angular that I ported into my app for a little fun.</p>
            <a href="#games" class="btn btn-primary">See Example</a>
            <div class="devimage">
                <img src="../../../assets/media/typescript_logo_new.png" title="TypeScript"/>
                <img src="../../../assets/media/javascript-logo.png" title="JavaScript"/>
                <img src="../../../assets/media/angular-logo.png" title="Angular"/>
            </div>
        </div>
    </div>
</div>
<div id="websiteswrapper">
    <h4>
        Other Websites
    </h4>
    <p>
        Below are a few other websites I either own or had a part in building. I think these give the best examples of building a whole web app from writing the code to creating your own domian and hosting as well as getting SSL certs to work.  
    </p>
</div>
<div class="card-deck">
    <div class="card bg-primary">
        <img class="card-img-top" src="https://lh3.googleusercontent.com/lGiAKz7ZqlOE53hkudiubI7vqPLb7Kx0k3eKDU8eDWH63D5tc7WKGMJNUjexkqOCS4iUl1ANVJlwHVWs62nklUN05GN93BQHj05nZub_dU8QN2KCy-gmbwleQBRc76k4Rz_Xw2uMxjU=w2400" alt="Card image">
        <div class="card-body text-center">
            <h4 class="card-title titlehighlight">SmallGuyPromo.com</h4>
            <p class="card-text">This is a wordpress site I made for a friend at work.  He has taken ownership of it but I got it off the ground and built some of the core elements of the site as well as the themes.</p>
            <a href="https://www.smallguypromo.com/" class="btn btn-primary">See Site</a>
            <div class="devimage">
                <img src="../../../assets/media/php_logo.png" title="PHP"/>
                <img src="../../../assets/media/wordpress_logo.png" title="WordPress"/>
            </div>
        </div>
    </div>
    <div class="card bg-light">
        <img class="card-img-top" src="https://lh3.googleusercontent.com/JSc5I_3UGyKZVPjqrbWHk1-KYxq9UVqmro1HgsOKZ19WYDn3uqfUzgeyChSjKaawaoEdnA8-RQ7i3EBQuHHJYBWWJpLfRzZ8fkY6Kuq9xxBboolXckm_LmU2mVQb4Lw67pn0V7FKTIU=w2400" alt="Card image">
        <div class="card-body text-center">
            <h4 class="card-title titlehighlight">AmateurAstronomer.net</h4>
            <p class="card-text">I built another website for my hobby in astronomy, but more blog focused. (will take you out of this site)</p>
            <a href="https://amateurastronomer.net/#/" class="btn btn-primary">See Site</a>
            <div class="devimage">
                <img src="../../../assets/media/angular-logo.png" title="Angular"/>
                <img src="../../../assets/media/mongo-logo.png" title="Mongo"/>
                <img src="../../../assets/media/aspnetcore-logo.png" title=".NetCore"/>
                <img src="../../../assets/media/Azure-logo.png" title="Azure"/>
            </div>
        </div>
    </div>
    <div class="card bg-primary">
        <img class="card-img-top" src="https://lh3.googleusercontent.com/rTxKGHvt47UCxNaXkBr3zZXg6RYfhXNcykIUXGn0up19lGmwlRUqZI6VvmNUAfEft--avX3PykcaDwPI8bbAt8z2be2UjNiGGCALsN5hu8Ba-xWZT0bNBx-b16rHHA_jksdg2ZfUr-0=w2400" alt="Card image">
        <div class="card-body text-center">
            <h4 class="card-title titlehighlight">Next.net</h4>
            <p class="card-text">Left this as a place holder for my next app, to keep pushing myself to create.</p>
            <p class="btn btn-primary">Next</p>
        </div>
    </div>
</div>