<div id="welcomewrapper">
    <h4>This Website:</h4>
    <p>
        Hello, and welcome to my site.  This is a webapp I made to occupy some time with the corona virus going around and me self-isolating.  Plus I can potentially use this to show off some skills as well as have a good reference for myself later on.  The hope also is that one day might be able to use this in an interview setting, where it should be a good example of my code as I can just say go to my URL and look at my github.
    </p>
    <p>
        Most of the good stuff in this site is on the code example pages, in them at least on desktop there is a tool overlay on the right that gives an explaination of what is being used.  Its hidden on mobile as it takes up a lot of screen.
    </p>
    <p>
        Stack used in this site:
        <br/>
        <img src="../../../assets/media/angular-logo.png" title="Angular"/>
        <img src="../../../assets/media/mongo-logo.png" title="Mongo"/>
        <img src="../../../assets/media/aspnetcore-logo.png" title=".NetCore"/>
        <img src="../../../assets/media/Azure-logo.png" title="Azure"/>
        <br/>
        Some of the services used:
        <br/>
        <img src="../../../assets/media/googlemap-logo.png" title="Google Maps"/>
        <img src="../../../assets/media/analytics-logo.png" title="Google Analytics"/>
        <img src="../../../assets/media/twitter-logo.png" title="Twitter"/>
        <br/>
        Some of the languages used:
        <br/>
        <img src="../../../assets/media/javascript-logo.png" title="JavaScript"/>
        <img src="../../../assets/media/csharp-logo.png" title="C#"/>
        <img src="../../../assets/media/typescript_logo_new.png" title="TypeScript"/>
    </p>
    <p>
        I also hope you can get a sense of my overall style as all of this site is my HTML and CSS.
    </p>
    <p>
        Current build is running an Angular 12.0 UI with a .Net/Core 6.0 backend, hosted on a Azure webservice.
    </p>
</div>