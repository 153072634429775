<div id="tooloverlay" [@slideInOut] [ngClass]="{'tooloverlaysm' : toolActive === false, 'tooloverlaylg' : toolActive === true}">
    <div id="toolbuttons">
        <button class="btn btn-primary btn-sm" title="Angular Forms" (click)="this.toolAction('angular')"><i class="fa fa-info fa-2x" aria-hidden="true"></i></button>
        <button class="btn btn-primary btn-sm" title="Close Tool Tip" (click)="this.toolClose()"><i class="fa fa-times-circle fa-2x" aria-hidden="true"></i></button>
    </div>
    <div id="angularformsoverlay" *ngIf="this.angularFormsOverlay" [@slideInOut]>
        <div id="angularforms-area">
            <angularforms-overlay></angularforms-overlay>
        </div>
    </div>
</div>
<div id="formswrapper">
    <h3>Angular forms</h3>
    <p>This page is just to show some basic angular forms on a page.  You can pretty much build anything with the forms you will see here.</p>
    <h4>Inputs</h4>
    <label class="formlabel">Text Input:</label>
    <input class="form-control" id="txtInput" [(ngModel)]="textInput" placeholder="Enter Text..."/>
    <label class="formlabel">Password Input:</label>
    <input class="form-control" type="password" id="pwdInput" [(ngModel)]="passwordInput" placeholder="Enter Text..."/>
    <label class="formlabel">Drowpdown Input:</label>
    <select class="custom-select" [(ngModel)]="selectInput">
        <option value="">Select an Option</option>
        <option value="one">here is one</option>
        <option value="two">here is two</option>
        <option value="three">here is three</option>
    </select>
    <br/>
    <label class="formlabel">Radio Input:</label>
    <br/>
    <label class=".radio-inline, inputLabel"><input class="form-control" type="radio" id="radioOne" value="1" [(ngModel)]="radioInput"/>Option 1</label>
    <label class=".radio-inline, inputLabel"><input class="form-control" type="radio" id="radioTwo" value="2" [(ngModel)]="radioInput"/>Option 2</label>
    <label class=".radio-inline, inputLabel"><input class="form-control" type="radio" id="radioThree" value="3" [(ngModel)]="radioInput"/>Option 3</label>
    <br/>
    <label class="formlabel">Checkbox Input:</label>
    <br/>
    <label class="checkbox-inline, inputLabel"><input type="checkbox" [(ngModel)]="ckboxOneInput">Option 1</label>
    <label class="checkbox-inline, inputLabel"><input type="checkbox" [(ngModel)]="ckboxTwoInput">Option 2</label>
    <label class="checkbox-inline, inputLabel"><input type="checkbox" [(ngModel)]="ckboxThreeInput">Option 3</label>
    <br/>
    <label class="formlabel">Expandable Text Area Input:</label>
    <br/>
    <textarea rows=5 [(ngModel)]="textAreaInput" placeholder="Enter Text..."></textarea>
    <h4>Buttons</h4>
    <div id="buttonarea">
        <button type="button" class="btn">Basic</button>
        <button type="button" class="btn btn-default">Default</button>
        <button type="button" class="btn btn-primary">Primary</button>
        <button type="button" class="btn btn-success">Success</button>
        <button type="button" class="btn btn-info">Info</button>
        <button type="button" class="btn btn-warning">Warning</button>
        <button type="button" class="btn btn-danger">Danger</button>
    </div>
    <h4>Spinners</h4>
    <div id="spinnersarea">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <h4>Cards</h4>
    <div id="cardarea">
        <div class="card-group">
            <div class="card" style="width:350px">
                <img class="card-img-top" src="https://www.w3schools.com/bootstrap4/img_avatar3.png" alt="Card image">
                <div class="card-body">
                    <h4 class="card-title">John Doe</h4>
                    <p class="card-text">Some example text.</p>
                    <a href="" class="btn btn-primary">See Profile</a>
                </div>
            </div>
            <div class="card" style="width:350px;">
                <div class="card-body bg-light">
                <h4 class="card-title">Jane Doe</h4>
                <p class="card-text">Some example text.</p>
                <a href="" class="btn btn-primary">See Profile</a>
                </div>
                <img class="card-img-bottom w3-grayscale-min" src="https://www.w3schools.com/bootstrap4/img_avatar5.png" alt="Card image" style="width:100%;margin-bottom:0">
            </div>
        </div>
    </div>
    <h4>Charts</h4>
    <div id="racechartdiv"></div> 
    <br/>
    <div id="radialchartdiv"></div>
    <h4>Tables</h4>
    <div id="tablearea">
        <label class="formlabel">Basic HTML Bootstrap Table (populated with input fields above values):</label>
        <table class="table table-striped table-hover">
            <thead>
                <th>Text Input</th>
                <th>Password Input</th>
                <th>Dropdown Input</th>
                <th>Radio Input</th>
                <th>Check Box One Input</th>
                <th>Check Box Two Input</th>
                <th>Check Box Three Input</th>
                <th>Text Area</th>
            </thead>
            <tbody>
                <td>{{this.textInput}}</td>
                <td>{{this.passwordInput}}</td>
                <td>{{this.selectInput}}</td>
                <td [ngClass]="{'red': radioInput == '3','green': radioInput == '1','yellow': radioInput == '2'}">{{this.radioInput}}</td>
                <td><input type="checkbox" [(ngModel)]="ckboxOneInput"></td>
                <td><input type="checkbox" [(ngModel)]="ckboxTwoInput"></td>
                <td><input type="checkbox" [(ngModel)]="ckboxThreeInput"></td>
                <td>{{this.textAreaInput}}</td>
            </tbody>
        </table>
    </div>
    <div id="tablemobile">
        Tables just look terriable on mobile, you can only do so much.  So I have hidden them, to see hold phone sideways or look on desktop site.
    </div>
</div>