<h5>Google Map API
    <img src="../../../assets/media/googlemap-logo.png" title="Google Maps"/>
</h5>
<p>
    <span class="skillhighlight">Chart Themes</span> - at the top you can select Silver, Night, and Normal
    <br/><span class="skillhighlight">Google GeoLocation API</span> - at the top you can search for an address and see that marker show up using the Google API
    <br/>
    <br/>
    <span class="skillhighlight"><i class="fa fa-map-marker fa-inverse"></i>Markers</span> - Displays a few company HQ markers on the the map with logos from the GoogleMaps API controller on the backend
    <br/>
    <br/>
    <span class="skillhighlight"><i class="fa fa-user fa-inverse"></i>Find You</span> - If you accept letting the site use your location, will display a user icon on your location and pan to it
    <br/>
    <br/>
    <span class="skillhighlight"><i class="fa fa-road fa-inverse"></i>Traffic</span> - Displays Traffic Layer from Google Map API
    <br/>
    <br/>
    <span class="skillhighlight"><i class="fa fa-bolt fa-inverse"></i>Weather</span> - Displays NOAA wearger KML Layer
    <br/>
    <br/>
    <span class="skillhighlight"><i class="fa fa-cloud fa-inverse"></i>Air Quality</span> - Displays Air Quality KML Layer
    <br/>
    <br/>
    <span class="skillhighlight"><i class="fa fa-fire fa-inverse"></i>Fire</span> - Displays Fire KML Layer from USDA Forest Service
    <br/>
    <br/>
    <span class="skillhighlight"><i class="fa fa-pencil fa-inverse"></i>Drawing</span> - Allows Map drawing through Google API, I recommend adding the markers and then drawing a circle that encapsulates them for a discription under the map
    <br/>
    <br/>
    Also If you use the line and click and then double click you will get a distance calculation in miles below the map
    <br/>
    <br/>
    <span class="skillhighlight"><i class="fa fa-map-signs fa-inverse"></i>Directions</span> - Allows To and From address. Direction will display on map as well as step by step under the map
</p>
<br/>
<h5>API Calls
    <img src="../../../assets/media/aspnetcore-logo.png" title=".NetCore"/>
</h5>
<p>
    Google Maps is basically all API calls, with special notes for the GeoLocation stuff.  The Markers are also brought in with the API project in the Google Controller
</p>
